import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import("./App")
  .then(({ App }) => {
    // Use moduleA
    ReactDOM.render(<App />, document.getElementById("inbooma"));
    serviceWorker.unregister();
  })
  .catch(err => {
    // Handle failure
    console.log(err);
    alert("Error loading booking app");
  });
